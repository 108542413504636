export const projectCategories = [
  {
    key: 'content'
  },
  {
    key: 'design'
  },
  {
    key: 'web'
  },
  {
    key: 'extension'
  }
]

export const projects = [
  {
    key: 'nextrainbow.project1',
    category: 'web',
    url: 'https://tnrbhk.org/',
    date: '2020-11-26',
    thumbnail: 'https://asset.alyssachan.space/tnrbhk.org.png',
    cover: 'https://asset.alyssachan.space/tnrbhk.org.png',
    client: 'nextrainbow'
  },
  {
    key: 'health1stcare.project2',
    category: 'web',
    url: 'https://health1stcare.com/',
    date: '2020-10-04',
    thumbnail: 'https://asset.alyssachan.space/health1stcare.com.png',
    cover: 'https://asset.alyssachan.space/health1stcare.com.png',
    client: 'health1stcare'
  },
  {
    key: 'unicoji.project1',
    category: 'extension',
    url: 'https://chrome.google.com/webstore/detail/unicoji-unicode-%2B-emoji/edbciehemfmfklmljkhonhphlccabend?hl=zh-TW',
    date: '2020-08-23',
    thumbnail: 'https://asset.alyssachan.space/unicoji.png',
    cover: 'https://asset.alyssachan.space/unicoji.png',
    client: 'unicoji'
  },
  {
    key: 'kidsartland.project1',
    category: 'web',
    url: 'https://kidsartland.com/',
    date: '2020-07-09',
    thumbnail: 'https://asset.alyssachan.space/kidsartland.com.png',
    cover: 'https://asset.alyssachan.space/kidsartland.com.png',
    client: 'kidsartland'
  },
  {
    key: 'health1stcare.project1',
    category: 'design',
    url: 'https://health1stcare.com/',
    date: '2020-07-06',
    thumbnail: 'https://asset.alyssachan.space/health1stcare-packing.png',
    cover: 'https://asset.alyssachan.space/health1stcare-packing.png',
    client: 'health1stcare'
  },
  {
    key: 'fry4future.project1',
    category: 'content',
    url: 'https://youtube.com/Fry4Future',
    date: '2020-04-27',
    thumbnail: 'https://asset.alyssachan.space/fry4future-youtube.png',
    cover: 'https://asset.alyssachan.space/fry4future-youtube.png',
    client: 'fry4future'
  },
  {
    key: 'flywheelchina.project2',
    category: 'web',
    url: 'https://flywheelchina.org/',
    date: '2020-01-21',
    thumbnail: 'https://asset.alyssachan.space/flywheelchina.org.png',
    cover: 'https://asset.alyssachan.space/flywheelchina.org.png',
    client: 'flywheelchina'
  },
  {
    key: 'flywheelchina.project1',
    category: 'web',
    url: 'https://flywheelchina.com/',
    date: '2020-01-14',
    thumbnail: 'https://asset.alyssachan.space/flywheelchina.com.png',
    cover: 'https://asset.alyssachan.space/flywheelchina.com.png',
    client: 'flywheelchina'
  },
  {
    key: 'healthguard.project1',
    category: 'web',
    url: 'https://healthguardsleep.com/',
    date: '2019-11-11',
    thumbnail: 'https://asset.alyssachan.space/healthguardsleep.com.png',
    cover: 'https://asset.alyssachan.space/healthguardsleep.com.png',
    client: 'healthguard'
  },
  {
    key: 'wealthytalent.project1',
    category: 'web',
    url: 'https://wealthytalent.com/',
    date: '2019-09-13',
    thumbnail: 'https://asset.alyssachan.space/wealthytalent.com.png',
    cover: 'https://asset.alyssachan.space/wealthytalent.com.png',
    client: 'wealthytalent'
  },
  {
    key: 'hkbigtaxi.project1',
    category: 'web',
    url: 'https://hkbigtaxi.com/',
    date: '2019-09-04',
    thumbnail: 'https://asset.alyssachan.space/hkbigtaxi.com.png',
    cover: 'https://asset.alyssachan.space/hkbigtaxi.com.png',
    client: 'hkbigtaxi'
  },
  {
    key: 'tastycatering.project1',
    category: 'web',
    url: 'https://order.tasty-catering.hk/',
    date: '2018-12-10',
    thumbnail: 'https://asset.alyssachan.space/order.tasty-catering.hk.png',
    cover: 'https://asset.alyssachan.space/order.tasty-catering.hk.png',
    client: 'tastycatering'
  },
  {
    key: 'gsh.project1',
    category: 'web',
    url: 'https://gsh.capital/',
    date: '2018-11-22',
    thumbnail: 'https://asset.alyssachan.space/gsh.capital.png',
    cover: 'https://asset.alyssachan.space/gsh.capital.png',
    client: 'gsh'
  },
  {
    key: 'invis.project1',
    category: 'web',
    url: 'https://invis.hk/',
    date: '2018-09-29',
    thumbnail: 'https://asset.alyssachan.space/invis.hk.png',
    cover: 'https://asset.alyssachan.space/invis.hk.png',
    client: 'invis'
  },
  {
    key: 'nikistorage.project1',
    category: 'web',
    url: 'https://nikistorage.com/',
    date: '2018-06-04',
    thumbnail: 'https://asset.alyssachan.space/nikistorage.com.png',
    cover: 'https://asset.alyssachan.space/nikistorage.com.png',
    client: 'nikistorage'
  },
  {
    key: 'highintelligence.project1',
    category: 'web',
    url: 'https://highintelligence.com.hk/',
    date: '2018-02-26',
    thumbnail: 'https://asset.alyssachan.space/highintelligence.com.hk.png',
    cover: 'https://asset.alyssachan.space/highintelligence.com.hk.png',
    client: 'highintelligence'
  },
  {
    key: 'tkpaa.project1',
    category: 'web',
    url: 'https://alumni.tkp.edu.hk/',
    date: '2016-10-31',
    thumbnail: 'https://asset.alyssachan.space/alumni.tkp.edu.hk.png',
    cover: 'https://asset.alyssachan.space/alumni.tkp.edu.hk.png',
    client: 'tkpaa'
  }
]
