import React from 'react'
import { RiMessengerLine, RiInstagramLine, RiWeiboLine, RiBriefcaseLine, RiQuestionLine, RiCodeSSlashLine } from 'react-icons/ri'

export const contactMethods = {
  pm: [
    {
      key: 'messenger',
      url: 'https://m.me/alyssa.tips',
      text: '@alyssa.tips',
      icon: <RiMessengerLine />
    },
    {
      key: 'instagram',
      url: 'https://instagram.com/alyssa.tips22',
      text: '@alyssa.tips22',
      icon: <RiInstagramLine />
    },
    {
      key: 'weibo',
      url: 'https://weibo.com/alyssachanspace',
      text: '@Alyssa艾黎飒',
      icon: <RiWeiboLine />
    }
  ],
  email: [
    {
      key: 'business_email',
      url: 'biz@alyssachan.space',
      icon: <RiBriefcaseLine />
    },
    {
      key: 'general_email',
      url: 'help@alyssachan.space',
      icon: <RiQuestionLine />
    },
    {
      key: 'support_email',
      url: 'support@alyssachan.space',
      icon: <RiCodeSSlashLine />
    }
  ]
}
