export const languages = [
  {
    id: 'en',
    text: 'EN'
  },
  {
    id: 'zh-HK',
    text: '繁'
  },
  {
    id: 'zh-CN',
    text: '简'
  }
]
