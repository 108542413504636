import React from 'react'
import { useTranslation } from 'react-i18next'
import { contactMethods } from './constants/contactMethods'
import * as styles from '../style.module.scss'

const Contact = () => {
  const { t } = useTranslation()

  return (
    <section id='contact'>
      <div className={styles.container}>
        <h2>{t('contact')}</h2>
        <p>{t('contact_description')}</p>
        <div className={styles.grid}>
          <div>
            <h3>{t('private_message')}</h3>
            <ul>
              {
                contactMethods.pm.map(contact =>
                  <li key={contact.key}>
                    <a href={contact.url}>
                      {contact.icon}
                      <div>
                        <h4>{t(contact.key)}</h4>
                        <span>{t(contact.text)}</span>
                      </div>
                    </a>
                  </li>
                )
              }
            </ul>
          </div>
          <div>
            <h3>{t('email')}</h3>
            <ul>
              {
                contactMethods.email.map(email =>
                  <li key={email.key}>
                    <a href={`mailto:${email.url}`}>
                      {email.icon}
                      <div>
                        <h4>{t(email.key)}</h4>
                        <span>{t(email.url)}</span>
                      </div>
                    </a>
                  </li>
                )
              }
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
