import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import Loader from './src/Loader'
import Meta from './src/Meta'
import Header from './src/Header'
import Main from './src/Main'
import Footer from './src/Footer'
import BackToTop from './src/BackToTop'
import { personAlyssa } from './src/constants/schema'

import './i18n'

const App = () => {
  const { t } = useTranslation('meta')
  return (
    <>
      <Meta title={t('title')} description={t('description')} url='https://alyssachan.space/' schema={personAlyssa} />
      <Header />
      <Main />
      <Footer />
      <BackToTop />
    </>
  )
}

ReactDOM.render(
  <Suspense fallback={<Loader />}>
    <App />
  </Suspense>,
  document.getElementById('app')
)
