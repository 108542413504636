export const personAlyssa = `{
  "@context": "https://schema.org",
  "@type": "Person",
  "name": "Alyssa Chan",
  "givenName": "Alyssa",
  "familyName": "Chan",
  "jobTitle": "Consultant",
  "description": "Content Creator",
  "gender": "http://schema.org/Female",
  "alumniOf": {
    "@type": "CollegeOrUniversity",
    "name": "The Hong Kong Polytechnic University",
    "url": "https://www.polyu.edu.hk/",
    "sameAs": "https://en.wikipedia.org/wiki/Hong_Kong_Polytechnic_University"
  },
  "birthPlace": "China",
  "nationality": "China",
  "email": "mailto:alyssa@alyssachan.space",
  "image": "https://alyssachan.space/alyssa-chan.png",
  "url": "https://alyssachan.space/",
  "sameAs": [
    "https://www.youtube.com/channel/UCVcY0oInOCDO0QOKgj7g7WQ",
    "https://www.facebook.com/alyssachan.space/",
    "https://www.instagram.com/alyssachan.space/",
    "https://www.linkedin.com/in/alyssachanspace",
    "https://twitter.com/alyssachanspace",
    "https://www.pinterest.com/alyssachanspace/",
    "https://www.behance.net/alyssachan",
    "https://github.com/alyssachanspace",
    "https://www.toutiao.com/c/user/1789647619888707/#mid=1667085540056078",
    "https://www.ixigua.com/home/1789647619888707",
    "https://v.douyin.com/JyoqpVr/",
    "https://live.kuaishou.com/profile/alyssachan",
    "https://weibo.com/alyssachanspace",
    "https://www.xiaohongshu.com/user/profile/5ec3b491000000000101e403",
    "https://www.zhihu.com/people/alyssachan",
    "https://www.ximalaya.com/zhubo/104363021/"
  ]
}`
