@font-face {
  font-family: 'icomoon';
  src:  url('./src/fonts/icomoon.eot?l7nvgq');
  src:  url('./src/fonts/icomoon.eot?l7nvgq#iefix') format('embedded-opentype'),
    url('./src/fonts/icomoon.ttf?l7nvgq') format('truetype'),
    url('./src/fonts/icomoon.woff?l7nvgq') format('woff'),
    url('./src/fonts/icomoon.svg?l7nvgq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"], [data-type="icon"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

footer [data-type="icon"] {
  font-size: 20px;
  color: #fff;
}

.icon-facebook {
  &::before {
    content: "\e900";
  }
  &:hover::before {
    color: #3c5a99;
  }
}
.icon-github {
  &::before {
    content: "\e901";
  }
  &:hover::before {
    color: #333;
  }
}
.icon-instagram {
  &::before {
    content: "\e902";
  }
  &:hover::before {
    color: #833ab4;
  }
}
.icon-linkedin {
  &::before {
    content: "\e903";
  }
  &:hover::before {
    color: #0077b5;
  }
}
.icon-pinterest {
  &::before {
    content: "\e904";
  }
  &:hover::before {
    color: #e60023;
  }
}
.icon-toutiao {
  &::before {
    content: "\e905";
  }
  &:hover::before {
    color: #ff373c;
  }
}
.icon-twitter {
  &::before {
    content: "\e906";
  }
  &:hover::before {
    color: #1da1f2;
  }
}
.icon-weibo {
  &::before {
    content: "\e907";
  }
  &:hover::before {
    color: #e6162d;
  }
}
.icon-youtube {
  &::before {
    content: "\e908";
  }
  &:hover::before {
    color: #f00;
  }
}