import React from 'react'
import { useTranslation } from 'react-i18next'
import * as styles from '../style.module.scss'

const About = () => {
  const { t } = useTranslation('about')

  return (
    <section id='about' className={styles.about}>
      <div className={styles.container}>
        <h2>{t('title')}</h2>
        <div className={styles.profile_pic}><img src='https://alyssachan.space/alyssa.jpeg' alt='Alyssa Chan' /></div>
        <div className={styles.text_container} dangerouslySetInnerHTML={{ __html: t('background') }} />
      </div>
    </section>
  )
}

export default About
