import React from 'react'
import About from './About'
import Blog from './Blog'
import Video from './Video'
// import Photo from './Photo'
import Portfolio from './Portfolio'
import Contact from './Contact'

const Main = () => {
  return (
    <main>
      <About />
      <Blog />
      <Video />
      {/* <Photo /> */}
      <Portfolio />
      <Contact />
    </main>
  )
}

export default Main
