export const socialLinks = [
  {
    key: 'youtube',
    url: 'https://youtube.com/AlyssaChan',
    title: 'YouTube'
  },
  {
    key: 'facebook',
    url: 'https://facebook.com/alyssa.tips/',
    title: 'Facebook'
  },
  {
    key: 'instagram',
    url: 'https://instagram.com/alyssa.tips22/',
    title: 'Instagram'
  },
  {
    key: 'linkedin',
    url: 'https://linkedin.com/in/alyssatips',
    title: 'LinkedIn 領英'
  }
]
