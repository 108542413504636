import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import YouTubeVideo from './YouTubeVideo'
import * as styles from '../style.module.scss'

const Video = () => {
  const channelId = 'UCVcY0oInOCDO0QOKgj7g7WQ'
  const jsonUrl = 'https://api.rss2json.com/v1/api.json?rss_url=' + encodeURIComponent('https://www.youtube.com/feeds/videos.xml?channel_id=') + channelId

  const [data, setData] = useState([])

  useEffect(() => {
    axios.get(jsonUrl)
      .then(response => setData(response.data.items))
  }, [])

  const { t } = useTranslation()

  return (
    <section id='video' className={styles.video}>
      <div className={styles.container}>
        <h2>{t('video')}</h2>
        <p>{t('video_description')}</p>
        <div className={styles.grid}>
          {
            data.slice(0, 3).map((item, i) => <YouTubeVideo key={i} videoId={item.link.substr(item.link.indexOf('=') + 1)} title={item.title} />)
          }
        </div>
        <a className={styles.cta} href='https://www.youtube.com/channel/UCVcY0oInOCDO0QOKgj7g7WQ?sub_confirmation=1'>{t('more_videos')}</a>
      </div>
    </section>
  )
}

export default Video
