import React from 'react'
import preloader from 'url:./img/preloader.svg'
import * as styles from '../style.module.scss'

const Loader = () => {
  return (
    <div className={styles.preloader_container}>
      <img src={preloader} alt='Loading...' />
    </div>
  )
}

export default Loader
