import React from 'react'
import { useTranslation } from 'react-i18next'
import { socialLinks } from './constants/socialLinks'
import * as styles from '../style.module.scss'
import * as iconStyles from '../icon.module.scss'

const Footer = () => {
  const { t } = useTranslation()

  return (
    <footer>
      <div className={styles.container}>
        <p>© {(new Date().getFullYear())} {t('site_name')}{t('punctuation.full_stop')}{t('copyright_statement')}</p>
        <ul>
          {
            socialLinks.map(link =>
              <li key={link.key} id={link.key}>
                <a href={link.url} title={t(link.key)}>
                  <span className={iconStyles[`icon-${link.key}`]} data-type='icon' />
                </a>
              </li>
            )
          }
        </ul>
      </div>
    </footer>
  )
}

export default Footer
