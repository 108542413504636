import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import Post from './Post'
import * as styles from '../style.module.scss'

const Blog = () => {
  const jsonUrl = 'https://blog.alyssachan.space/wp-json/wp/v2/posts'

  const [data, setData] = useState([])

  useEffect(() => {
    axios.get(jsonUrl)
      .then(response => setData(response.data))
  }, [])

  const { t } = useTranslation()

  return (
    <section id='blog' className={styles.blog}>
      <div className={styles.container}>
        <h2>{t('blog')}</h2>
        <p>{t('blog_description')}</p>
        <div className={styles.grid}>
          {
            data.slice(0, 3).map(post => <Post key={post.id} link={post.link} title={post.title.rendered} excerpt={post.excerpt.rendered} featuredImage={post.fifu_image_url} alt={post.fifu_image_alt} />)
          }
        </div>
        <a className={styles.cta} href='https://blog.alyssachan.space'>{t('more_posts')}</a>
      </div>
    </section>
  )
}

export default Blog
