import React, { useState, useEffect } from 'react'
import upArrow from 'url:./img/up-arrow.svg'
import * as styles from '../style.module.scss'

const BackToTop = () => {
  const [showScroll, setShowScroll] = useState(false)
  const [prevOffset, setPrevOffset] = useState(0)

  const toggleScrollDirection = () => {
    const scrollY = window.scrollY
    if (scrollY === 0) {
      setShowScroll(false)
    }
    if (scrollY > prevOffset) {
      setShowScroll(false)
    } else if (scrollY < prevOffset && window.pageYOffset > 400) {
      setShowScroll(true)
    }
    setPrevOffset(scrollY)
  }
  useEffect(() => {
    window.addEventListener('scroll', toggleScrollDirection)
    return () => {
      window.removeEventListener('scroll', toggleScrollDirection)
    }
  })

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <img src={upArrow} className={showScroll ? `${styles.top_arrow} ${styles.show}` : styles.top_arrow} onClick={scrollTop} />
  )
}

export default BackToTop
