export const menuLinks = [
  {
    key: 'about',
    href: '#about'
  },
  {
    key: 'blog',
    href: '#blog'
  },
  {
    key: 'video',
    href: '#video'
  },
  {
    key: 'portfolio',
    href: '#portfolio'
  },
  {
    key: 'contact',
    href: '#contact'
  }
]
