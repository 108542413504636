import React, { Component } from 'react'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import * as styles from '../style.module.scss'

const contentStyle = {
  width: '85vw',
  paddingBottom: '50%',
  height: '0',
  background: 'transparent',
  border: '0'
}
class YouTubeVideo extends Component {
  render () {
    const { videoId, title } = this.props

    return (
      <Popup trigger={<div><img src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`} alt={title} /><h3>{title}</h3></div>} modal {...{ contentStyle }}>
        <div className={styles.responsive}><iframe src={`https://youtube.com/embed/${videoId}?controls=1&showinfo=0&rel=0`} width='600' height='340' frameBorder='0' allowFullScreen /></div>
      </Popup>
    )
  }
}

export default YouTubeVideo
