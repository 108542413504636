import React from 'react'
import { useTranslation } from 'react-i18next'
import { projects } from './constants/projects'
import * as styles from '../style.module.scss'

const Portfolio = () => {
  const { t } = useTranslation('project')

  return (
    <section id='portfolio' className={styles.portfolio}>
      <div className={styles.container}>
        <h2>{t('title')}</h2>
        <p>{t('description')}</p>
        <div className={styles.grid}>
          {
            projects
              .sort((a, b) => new Date(b.date) - new Date(a.date))
              .map(project =>
                <div key={project.key} className={styles.overlay_container}>
                  <a href={project.url}>
                    <h4>{t(`${project.key}.item`)}</h4>
                    <img src={project.thumbnail} alt={t(`${project.key}.item`)} />
                  </a>
                </div>
              )
          }
        </div>
      </div>
    </section>
  )
}

export default Portfolio
