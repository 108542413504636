import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MdTranslate, MdMenu, MdClear } from 'react-icons/md'
import { menuLinks } from './constants/menuLinks'
import { languages } from './constants/languages'
import * as styles from '../style.module.scss'

function Header () {
  const { t, i18n } = useTranslation()
  const changeLanguage = lng => {
    i18n.changeLanguage(lng)
  }

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isLanguageSwitcherOpen, setLanguageSwitcherOpen] = useState(false)
  const [isSticky, setIsSticky] = useState(false)
  const [prevOffset, setPrevOffset] = useState(0)

  const toggleScrollDirection = () => {
    closeLanguageSwitcher()
    const scrollY = window.scrollY
    if (scrollY === 0) {
      setIsSticky(false)
    }
    if (scrollY > prevOffset) {
      setIsSticky(false)
    } else if (scrollY < prevOffset) {
      setIsSticky(true)
    }
    setPrevOffset(scrollY)
  }

  useEffect(() => {
    window.addEventListener('scroll', toggleScrollDirection)
    return () => {
      window.removeEventListener('scroll', toggleScrollDirection)
    }
  })

  useEffect(() => {
    document.body.addEventListener('click', closeAll)

    return function cleanup () {
      window.removeEventListener('click', closeAll)
    }
  }, [])

  const closeSidemenu = () => {
    setIsMenuOpen(false)
  }

  const closeLanguageSwitcher = () => {
    setLanguageSwitcherOpen(false)
  }

  const closeAll = () => {
    closeSidemenu()
    closeLanguageSwitcher()
  }

  return (
    <header className={isSticky ? styles.sticky : ''}>
      <div className={styles.container}>
        <h1><a href='https://alyssachan.space'>{t('site_name')}</a></h1>
        <nav className={isMenuOpen ? styles.opened : ''}>
          {
            menuLinks.map(link =>
              <a key={link.key} href={link.href}>{t(link.key)}</a>)
          }
          <div className={styles.clickable}><MdTranslate onClick={() => { setLanguageSwitcherOpen(!isLanguageSwitcherOpen) }} /></div>
          <div className={isLanguageSwitcherOpen ? styles.opened : ''} id='language_switcher'>
            {
              languages.map(lang =>
                <button key={lang.id} id={lang.id} onClick={() => changeLanguage(lang.id)}>{lang.text}</button>)
            }
          </div>
        </nav>
        <span>
          {isMenuOpen
            ? <MdClear onClick={() => { setIsMenuOpen(false) }} />
            : <MdMenu onClick={() => { setIsMenuOpen(true) }} />}
        </span>
      </div>
    </header>
  )
}

export default Header
