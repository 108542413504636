import React from 'react'
import { Helmet } from 'react-helmet'
import { getLanguage } from './functions/getLanguage'

const Meta = ({ title, description, url, schema }) => {
  const lang = getLanguage()

  const author = () => {
    switch (lang) {
      case 'zh-HK':
        return 'Alyssa艾黎颯'
      case 'zh-CN':
        return 'Alyssa艾黎飒'
      default:
        return 'Alyssa Chan'
    }
  }

  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='author' content={author()} />
      <link rel='canonical' href={url} />
      <script type='application/ld+json'>{schema}</script>
    </Helmet>
  )
}

export default Meta
