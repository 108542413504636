import React, { Component } from 'react'

class Post extends Component {
  render () {
    const { link, title, excerpt, featuredImage, alt } = this.props

    return (
      <div>
        <a href={link}><img src={featuredImage} alt={alt} /><h3>{title}</h3></a>
        <div dangerouslySetInnerHTML={{ __html: excerpt }} />
      </div>
    )
  }
}

export default Post
